import axios, { AxiosRequestConfig } from 'axios';

interface Official {
  id: number;
  first_name: string;
  last_name: string;
}

interface Agency {
  id: number;
  name: string;
  logo_url: string | null;
}

interface OfficialCredentials {
  id: number;
  offical_credential_id: number;
  client_provided: boolean;
  status: number;
  created_at: string;
  updated_at: string;
  agency_official_id: number;
}

export interface AgencyGroups {
  id: number;
  agency_id: number;
  default: boolean;
  name: string;
}

export interface OfficialData {
  id: number;
  official_id: number;
  agency_id: number;
  title: string;
  email: string;
  img_url: string | null;
  agency_official_type_name: string | null;
  rank: string;
  role: string;
  verified: boolean;
  armed_permission: boolean;
  inviteStatus: boolean;
  status: string;
  official: Official;
  agency: Agency;
  groups: AgencyGroups[] | [];
  official_credentials: OfficialCredentials[];
}

export interface FormattedOfficials {
  id: number;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  avatarUrl: string | null;
  type: string | null;
  rank: string;
  role: string;
  verified: boolean;
  armedPermission: boolean;
  inviteStatus: boolean | string;
  lawEnforcementAgency: string;
  groups: AgencyGroups[];
  status: string;
}

export const getOfficials = async (
  accessToken: string
): Promise<{ data?: OfficialData[]; error?: any }> => {
  const options: AxiosRequestConfig = {
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios.get<OfficialData[]>(`/api/v1/agencies/1/officials`, options);
    return { data: response.data };
  } catch (error) {
    console.error('Error fetching officials:', error);
    return { error: 'Failed to fetch officials.' };
  }
};
