import { ReactElement, useEffect, useState } from 'react';
import { fetchAgencies, Agency } from '../networkRequest/agenciesService';
import { useAuth0 } from '@auth0/auth0-react';

export const CheckpointsPage = (): ReactElement => {
  const { getAccessTokenSilently } = useAuth0();
  const [agencies, setAgencies] = useState<Agency[]>([]);
  const [error, setError] = useState<string | null>(null);

  const getAgenciesWithToken = async (accessToken: string) => {
    const { data, error } = await fetchAgencies(accessToken);
    if (data) {
      setAgencies(data);
    }
    if (error) {
      setError(error);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (isMounted) {
          await getAgenciesWithToken(accessToken);
        }
      } catch (err: any) {
        if (isMounted) {
          setError(err.message || 'An unexpected error occurred.');
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);

  if (error) {
    return (
      <div>
        <div>Error: {error}</div>
      </div>
    );
  }

  return (
    <div>
      <div>Checkpoints</div>
      <ul>
        {agencies.map((agency) => (
          <li key={agency.id}>{agency.name}</li>
        ))}
      </ul>
    </div>
  );
};

export default CheckpointsPage;
