import { ReactElement } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Route, Routes } from 'react-router-dom';
import { CallbackPage } from './pages/callbackPage';
import { CheckpointsPage } from './pages/checkpointsPage';
import { HomePage } from './pages';
import { LocationsPage } from './pages/locationsPage';
import { ManageOfficialsPage } from './pages/manageOfficialsPage';
import PageLayout from './containers/pageLayout';
import './App.css';
import { PageLoader } from './components/PageLoader';

const App = (): ReactElement => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    const LoadingPage = PageLayout(PageLoader);
    return <LoadingPage />;
  }

  const Home = PageLayout(HomePage);
  const ManageOfficials = PageLayout(ManageOfficialsPage);
  const Locations = PageLayout(LocationsPage);
  const Checkpoints = PageLayout(CheckpointsPage);
  const Callback = PageLayout(CallbackPage);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="manage-officials" element={<ManageOfficials />} />
      <Route path="/locations" element={<Locations />} />
      <Route path="/checkpoints" element={<Checkpoints />} />
      <Route path="/callback" element={<Callback />} />
    </Routes>
  );
};

export default App;
