import { useEffect, useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  GridMoreVertIcon
} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DataTableButton from './buttons/dataTableTopButton';
import { FileUploadOutlined } from '@mui/icons-material';
import { Avatar, Box, Typography } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import { Theme, useTheme } from '@mui/material/styles';
import StatusChip from './statusChip';
import { formatAgenciesData } from '../dataformatter';
import { useAuth0 } from '@auth0/auth0-react';
import { AgencyGroups, FormattedOfficials, getOfficials } from '../networkRequest/officialsService';
import BulkDataHandler from './bulkDataHandler';

const CustomToolBar = (): JSX.Element => {
  return (
    <GridToolbarContainer sx={{ backgroundColor: 'skyBlue', padding: 1 }}>
      <DataTableButton startIcon={<AddIcon />} buttonText="Add Official" />
      <GridToolbarExport printOptions={{ disableToolbarButton: true, includeCheckboxes: true }} />
      <DataTableButton startIcon={<FileUploadOutlined />} buttonText="Email Invite" />
    </GridToolbarContainer>
  );
};

const staticColumns = [
  { field: 'lastName', headerName: 'Last Name', width: 150 },
  { field: 'title', headerName: 'Title', width: 150 },
  { field: 'type', headerName: 'Type', width: 150 },
  { field: 'role', headerName: 'Role', width: 150 }
];

export const AgencyOfficialsDataTable: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [officials, setOfficials] = useState<FormattedOfficials[]>([]);
  const theme: Theme = useTheme();

  useEffect(() => {
    const fetchOfficials = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        await getAgencyOfficials(accessToken);
      } catch (error: any) {
        console.error('Something went wrong', error.message);
      }
    };

    fetchOfficials();
  }, [getAccessTokenSilently]);

  const getAgencyOfficials = async (accessToken: string) => {
    const { data } = await getOfficials(accessToken);

    if (data) {
      const formatAgencyOfficialData = formatAgenciesData(data);
      setOfficials(formatAgencyOfficialData);
    }
  };

  const AvatarCell: React.FC<{ avatarUrl?: string | null; name: string }> = ({
    avatarUrl,
    name
  }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, height: '100%' }}>
      <Avatar sx={{ width: 24, height: 24 }} src={avatarUrl ? avatarUrl : undefined} alt="avatar" />
      <Typography variant="body2">{name}</Typography>
    </Box>
  );

  const GroupCell: React.FC<{ groups: AgencyGroups[] }> = ({ groups }) => (
    <>
      {groups.length > 0 && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, height: '100%' }}>
          <PeopleIcon sx={{ width: 24, height: 24 }} />
          <Typography variant="body2">{groups[0].name}</Typography>
        </Box>
      )}
    </>
  );

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      renderCell: (params: { row: FormattedOfficials }) => (
        <AvatarCell avatarUrl={params.row.avatarUrl} name={params.row.firstName} />
      )
    },
    ...staticColumns,
    {
      field: 'inviteStatus',
      headerName: 'Invite Status',
      renderCell: (params) => <StatusChip status={params.value} theme={theme} />,
      width: 150
    },
    {
      field: 'group',
      headerName: 'Group(s)',
      renderCell: (params: { row: FormattedOfficials }) => {
      return<GroupCell groups={params.row.groups} />},
      width: 150
    },
    {
      field: 'status',
      headerName: 'Job Status',
      renderCell:  (params: { row: FormattedOfficials }) => <StatusChip status={params.row.status} theme={theme} />,
      width: 150
    },
    {
      field: 'userDetails',
      headerName: '',
      renderCell: () => <GridMoreVertIcon />,
      width: 50
    }
  ];

  return (
    <Box sx={{ flexGrow: 1, height: '100%' }}>
      <DataGrid
        columns={columns}
        rows={officials}
        checkboxSelection
        autoHeight
        slots={{
          toolbar: CustomToolBar,
          noRowsOverlay: BulkDataHandler
        }}
        sx={{
          '--DataGrid-overlayHeight': '300px',
          height: '100%',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '600'
          },
          '& .MuiDataGrid-toolbarContainer': {
            backgroundColor: '#E3F2FD'
          },
          '& .MuiDataGrid-columnHeaderTitle, & .MuiButton-text, & [data-testid="IndeterminateCheckBoxIcon"], & [data-testid="CheckBoxIcon"], & [data-testid="CheckBoxOutlineBlankIcon"]':
            {
              color: 'navyBlue'
            },
          '& .MuiDataGrid-overlayWrapperInner': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }
        }}
      />
    </Box>
  );
};

export default AgencyOfficialsDataTable;
