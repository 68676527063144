import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    brightTeal: React.CSSProperties['color'];

    darkTeal: React.CSSProperties['color'];

    navyBlue: React.CSSProperties['color'];

    skyBlue: React.CSSProperties['color'];
  }

  interface PaletteOptions {
    brightTeal?: React.CSSProperties['color'];

    darkTeal?: React.CSSProperties['color'];

    navyBlue?: React.CSSProperties['color'];

    skyBlue?: React.CSSProperties['color'];
  }
}

const verifiTheme = createTheme({
  palette: {
    primary: {
      main: '#000'
    },
    text: {
      primary: '#2B455E'
    },
    background: {
      default: '#FFF'
    },
    brightTeal: '#00FAC2',

    darkTeal: '#00B8A8',

    navyBlue: '#2B455E',

    skyBlue: '#00D1FF'
  }
});

export default verifiTheme;
