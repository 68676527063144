import axios from 'axios';

export interface Agency {
  id: string;
  name: string;
  logoUrl: string;
}

export const fetchAgencies = async (
  accessToken: string
): Promise<{ data?: Agency[]; error?: string }> => {
  try {
    const response = await axios.get<Agency[]>(`/api/v1/agencies`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    return { data: response.data };
  } catch (error: any) {
    console.error('Error fetching agencies', error);
    return { error: error.message || 'An unknown error occurred' };
  }
};
