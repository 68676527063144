import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';

const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      authorizationParams: {
        connection: 'email'
      },
      appState: {
        returnTo: '/'
      }
    });
  };
  return (
    <Button variant="outlined" color="info" size="small" onClick={handleLogin}>
      Login
    </Button>
  );
};

export default LoginButton;
