import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';

const SignupButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignup = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/profile'
      },
      authorizationParams: {
        screen_hint: 'signup'
      }
    });
  };

  return (
    <Button variant="contained" color="info" size="small" onClick={handleSignup}>
      Sign Up
    </Button>
  );
};

export default SignupButton;
