import { ReactElement } from 'react';
import NavBar from '../components/navBar';
import { Container } from '@mui/material';

export const CallbackPage = (): ReactElement => {
  return (
    <div>
      <NavBar />
      <Container />
    </div>
  );
};
