import { copy, types } from './definitions';
import { Box } from '@mui/material';
import placeholderStar from '../../assets/placeholderStar.svg';

export const navigationMenusData: types.MenuList = {
  menuList: [
    {
      menu: copy.menuTitle.manageOfficials,
      list: [
        {
          name: copy.manageOfficials.groups,
          icon: <Box component="img" src={placeholderStar} alt="star" />,
          action: () => console.log('hello'),
          authorized: true
        },
        {
          name: copy.manageOfficials.agencyOfficials,
          icon: <Box component="img" src={placeholderStar} alt="star" />,
          action: () => console.log('hello')
        },
        {
          name: copy.manageOfficials.addAnOfficial,
          icon: <Box component="img" src={placeholderStar} alt="star" />,
          action: () => console.log('hello')
        },
        {
          name: copy.manageOfficials.addOfficalsInBulk,
          icon: <Box component="img" src={placeholderStar} alt="star" />,
          action: () => console.log('hello')
        }
      ]
    },
    {
      menu: copy.menuTitle.checkpoints,
      list: [
        {
          name: copy.checkpoints.existingCheckpoints,
          icon: <Box component="img" src={placeholderStar} alt="star" />,
          action: () => console.log('hello')
        },
        {
          name: copy.checkpoints.addCheckpoint,
          icon: <Box component="img" src={placeholderStar} alt="star" />,
          action: () => console.log('hello')
        }
      ]
    },
    {
      menu: copy.menuTitle.checkpointAccessLog,
      list: [
        {
          name: copy.checkpointAccessLog.accessRecordsForAgency,
          icon: <Box component="img" src={placeholderStar} alt="star" />,
          action: () => console.log('hello')
        },
        {
          name: copy.checkpointAccessLog.advancedRecordSearch,
          icon: <Box component="img" src={placeholderStar} alt="star" />,
          action: () => console.log('hello')
        }
      ]
    },
    {
      menu: copy.menuTitle.connectReader,
      list: [
        {
          name: copy.connectReader.instructions,
          icon: <Box component="img" src={placeholderStar} alt="star" />,
          action: () => console.log('hello')
        },
        {
          name: copy.connectReader.connectIdReader,
          icon: <Box component="img" src={placeholderStar} alt="star" />,
          action: () => console.log('hello')
        }
      ]
    },
    {
      menu: copy.menuTitle.profile,
      list: [
        {
          name: copy.profile.agencyProfile,
          icon: <Box component="img" src={placeholderStar} alt="star" />,
          action: () => console.log('hello')
        }
      ]
    },
    {
      menu: copy.menuTitle.agencyInfo,
      list: [
        {
          name: copy.agencyInfo.agencyLogo,
          icon: <Box component="img" src={placeholderStar} alt="star" />,
          action: () => console.log('hello')
        },
        {
          name: copy.agencyInfo.teamMembers,
          icon: <Box component="img" src={placeholderStar} alt="star" />,
          action: () => console.log('hello')
        },
        {
          name: copy.agencyInfo.inviteTeamMembers,
          icon: <Box component="img" src={placeholderStar} alt="star" />,
          action: () => console.log('hello')
        },
        {
          name: copy.agencyInfo.permissions,
          icon: <Box component="img" src={placeholderStar} alt="star" />,
          action: () => console.log('hello')
        }
      ]
    }
  ]
};
