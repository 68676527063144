import { ReactElement } from 'react';

export const LocationsPage = (): ReactElement => {
  return (
    <div>
      <div>Locations</div>
    </div>
  );
};

export default LocationsPage;
