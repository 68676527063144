import { ReactElement } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Typography } from '@mui/material';
import LoginButton from '../components/buttons/loginButton';
import LogoutButton from '../components/buttons/logoutButton';

const HomePage = (): ReactElement => {
  const { user } = useAuth0();

  return (
    <Box>
      {!user && (
        <Box>
          <Typography variant="h1">Welcome to Verifi</Typography>
          <LoginButton />
        </Box>
      )}

      {user && (
        <Box>
          <Typography variant="h1">My Agency</Typography>

          <Typography variant="h3">Welcome {user.name} to your agency</Typography>
          <LogoutButton />
        </Box>
      )}
    </Box>
  );
};

export default HomePage;
