import { ReactElement, useEffect, useState } from 'react';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { types } from '../../containers/pageLayout/definitions';

const NavigationList = ({ menuList }: types.MenuList): ReactElement => {
  const [open, setOpen] = useState<{ [key: number]: boolean }>({});
  const isSmallScreen = useMediaQuery('(max-width:1024px)');

  useEffect(() => {
    /**
     * Set all menus to open by default
     *  */
    const initialOpenState: { [key: number]: boolean } = menuList.reduce(
      (acc: { [key: number]: boolean }, _, index: number) => {
        acc[index] = true;
        return acc;
      },
      {}
    );
    setOpen(initialOpenState);
  }, [menuList]);

  /**
   * Allow for menus to be opened and closed individually
   */
  const handleClick = (index: number) => {
    setOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };
  const renderMenus = () => {
    return menuList.map((item, index) => {
      return (
        <Box component="div" key={`${item}-${index}`}>
          <ListItemButton onClick={() => handleClick(index)}>
            <ListItemText primary={item.menu} />
            {open[index] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open[index]} timeout="auto" unmountOnExit>
            <Box component="div" marginTop="0">
              <List component="section">
                {item.list.map((itemList, index) => {
                  return (
                    <ListItem dense key={`${itemList}-${index}`}>
                      <ListItemButton sx={{ margin: -1 }} onClick={itemList.action}>
                        {itemList.icon}
                        <Typography variant="body2" gutterBottom>
                          {itemList.name}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Collapse>
        </Box>
      );
    });
  };

  return (
    <Box
      component="section"
      bgcolor="navyBlue"
      color="white"
      height='95vh'
      marginTop={!isSmallScreen ? 0 : '60px'}
    >
      {renderMenus()}
    </Box>
  );
};

export default NavigationList;
