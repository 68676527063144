import { useAuth0 } from '@auth0/auth0-react';
import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import SignupButton from './buttons/signupButton';
import LoginButton from './buttons/loginButton';
import LogoutButton from './buttons/logoutButton';

export const NavBar: React.FC = () => {
  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
  const { isAuthenticated } = useAuth0();
  return (
    <Box
      sx={{
        typography: 'body1',
        '& > :not(style) ~ :not(style)': {
          ml: 2
        }
      }}
      onClick={preventDefault}
    >
      {!isAuthenticated && (
        <>
          <SignupButton />
          <LoginButton />
        </>
      )}
      {isAuthenticated && (
        <>
          <Link component={RouterLink} to="/">
            Home
          </Link>
          <Link component={RouterLink} to="/manage-officials">
            Manage Officials
          </Link>
          <Link component={RouterLink} to="/locations">
            Locations
          </Link>
          <Link component={RouterLink} to="/checkpoints">
            Checkpoints
          </Link>
          <LogoutButton />
        </>
      )}
    </Box>
  );
};

export default NavBar;
