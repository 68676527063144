import React from 'react';
import { Button, ButtonProps } from '@mui/material';

interface DataTableButtonProps extends ButtonProps {
  startIcon: React.ReactNode;
  buttonText: string;
}

const DataTableButton: React.FC<DataTableButtonProps> = ({ startIcon, buttonText, ...props }) => {
  return (
    <Button
      variant="contained"
      startIcon={startIcon}
      size="small"
      disableElevation
      disableRipple
      sx={{
        backgroundColor: 'transparent',
        color: 'navyBlue',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)'
        },
        padding: (theme) => `${theme.spacing(0.5)} ${theme.spacing(0.625)}`,
        borderRadius: 0.5
      }}
      {...props}
    >
      {buttonText}
    </Button>
  );
};

export default DataTableButton;
