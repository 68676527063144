const copy = {
  menuTitle: {
    manageOfficials: 'Manage Officials',
    checkpoints: 'Checkpoints',
    checkpointAccessLog: 'Checkpoing Access Log',
    connectReader: 'ConnectReader',
    profile: 'Profile',
    agencyInfo: 'Agency Info'
  },
  manageOfficials: {
    groups: 'Groups',
    agencyOfficials: 'Agency Officials',
    addAnOfficial: 'Add an Official',
    addOfficalsInBulk: 'Add Officials in Bulk'
  },
  checkpoints: {
    existingCheckpoints: 'Existing Checkpoints',
    addCheckpoint: 'Add Checkpoint'
  },
  checkpointAccessLog: {
    accessRecordsForAgency: 'Access Records for Agency',
    advancedRecordSearch: 'Advanced Records Search'
  },
  connectReader: {
    instructions: 'Instructions',
    connectIdReader: 'connect ID Reader'
  },
  profile: {
    agencyProfile: 'Agency Profile'
  },
  agencyInfo: {
    agencyLogo: 'Agency Logo',
    teamMembers: 'Team Members',
    inviteTeamMembers: 'Invite Team Members',
    permissions: 'Permissions'
  },
  copyrightFooter: '© 2024, ID Verifi'
};

export default copy;
