import React from 'react';
import { Chip } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import { blue, green, orange, yellow } from '@mui/material/colors';

interface StatusChipProps {
  status: string;
  theme: Theme;
}

enum STATUS {
  DEACTIVATED = 'deactivated',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  EXPIRED = 'expired',
  ACCEPTED = 'accepted',
  NOT_SENT = 'not sent',
  SUSPENDED = 'suspended'
}

const StatusChip: React.FC<StatusChipProps> = ({ status, theme }): JSX.Element => {
  const formatStatusLabel = (status: string) =>
    status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

  switch (status) {
    case STATUS.DEACTIVATED:
    case STATUS.EXPIRED:
    case STATUS.SUSPENDED:
      return (
        <Chip
          label={formatStatusLabel(status)}
          icon={<ErrorOutline sx={{ fill: theme.palette.error.light }} />}
          sx={{ backgroundColor: yellow[100], color: 'navyBlue' }}
        />
      );
    case STATUS.INACTIVE:
      return <Chip label={formatStatusLabel(status)} variant="outlined" sx={{backgroundColor: yellow[100]}}/>;
    case STATUS.ACTIVE:
      return <Chip label={formatStatusLabel(status)} variant="outlined" />;
    case STATUS.PENDING:
      return <Chip label={formatStatusLabel(status)} sx={{ backgroundColor: blue[100] }} />;
    case STATUS.ACCEPTED:
      return (
        <Chip label={formatStatusLabel(status)} sx={{ backgroundColor: green[100] }} />
      );
    case STATUS.NOT_SENT:
      return <Chip label={formatStatusLabel(status)} sx={{ backgroundColor: orange[100] }} />;
    default:
      return <Chip label="Unknown" />;
  }
};

export default StatusChip;
