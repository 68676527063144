import { FormattedOfficials, OfficialData } from './networkRequest/officialsService';

export const formatAgenciesData = (data: OfficialData[]): FormattedOfficials[] => {
  return data.map((item) => ({
    id: item.official_id,
    firstName: item.official.first_name,
    lastName: item.official.last_name,
    title: item.title,
    email: item.email,
    avatarUrl: item.img_url,
    type: item.agency_official_type_name,
    rank: item.rank,
    role: item.role || 'Admin',
    verified: item.verified,
    armedPermission: item.armed_permission,
    inviteStatus: item.inviteStatus || 'Not Sent',
    lawEnforcementAgency: item.agency.name,
    groups: item.groups,
    status: item.status
  }));
};
