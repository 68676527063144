import React, { ReactElement, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Avatar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Menu, MenuOpen } from '@mui/icons-material';
import { NavigationList } from '../../components';
import { navigationMenusData } from './navigationMenusData';
import { copy } from './definitions';
import verifiLogo from '../../assets/verifilogo.svg';
import appBarVerifiLogo from '../../assets/appBarVerifiLog.svg';
import tanishaPlaceholder from '../../assets/profile pic.jpeg'

export const PageLayout = (Component: React.FC) => {
  return function EnhancedComponent(props: any) {
    const [open, setOpen] = useState<boolean>(true);
    const isSmallScreen = useMediaQuery('(max-width:1024px)');
    const isLaptopScreen = useMediaQuery('(min-width: 1440px)');

    useEffect(() => {
      if (isSmallScreen) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }, [isSmallScreen]);

    const toggleDrawer = () => {
      setOpen(!open);
    };

    const drawerWidth = 291;

    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
      open?: boolean;
    }>(({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
      })
    }));

    interface AppBarProps extends MuiAppBarProps {
      open?: boolean;
    }

    const AppBar = styled(MuiAppBar, {
      shouldForwardProp: (prop) => prop !== 'open'
    })<AppBarProps>(({ theme, open }) => ({
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        })
      })
    }));

    const DrawerHeader = (): ReactElement => {
      return (
        <Box
          bgcolor="#22374B"
          component="section"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={isLaptopScreen && !isSmallScreen ? '170px' : '120px'}
          padding={isLaptopScreen ? '18px 0 18px 0' : '35px 0 35px 0'}
          onClick={toggleDrawer}
        >
          <Box>
            <Box component="img" alt="verifi logo" src={verifiLogo} height="80px" width="120px" />
          </Box>
        </Box>
      );
    };

    const AppBarHeader = (): ReactElement => {
      return (
        <AppBar
          position="sticky"
          color="default"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            height: '65px',
            maxWidth: '1024px',
            padding: '0 12px'
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" padding="8px">
            <IconButton onClick={toggleDrawer}>{open ? <MenuOpen /> : <Menu />}</IconButton>
            <Box component="img" src={appBarVerifiLogo} alt="verifi logo" />
            <Avatar alt="Tanisha placeholder" src={tanishaPlaceholder} />
          </Box>
        </AppBar>
      );
    };

    return (
      <Box sx={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
        <Box>
          <CssBaseline />
          {isSmallScreen && <AppBarHeader />}
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box'
              }
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            {!isSmallScreen && <DrawerHeader />}

            <NavigationList menuList={navigationMenusData.menuList} />
            <Typography bgcolor="navyBlue" color="white" variant="caption" padding="15px">
              {copy.copyrightFooter}
            </Typography>
          </Drawer>
        </Box>
        <Main open={open} sx={{ marginLeft: isSmallScreen ? '5px' : '291px' }}>
          {/** Component will be placed alongside the SideNavigation */}
          <Component {...props} />
        </Main>
      </Box>
    );
  };
};

export default PageLayout;
