import React from 'react';
import { Container, Button, Typography } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

const BulkDataHandler: React.FC = () => {
  const theme: Theme = useTheme();

  const handleBulkImport = () => {};

  return (
    <Container
      sx={{
        maxWidth: 600
      }}>
      <Typography variant="h6" sx={{ padding: `${theme.spacing(2)} ${theme.spacing(3)}` }}>
        Let's Get Started!
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ padding: `${theme.spacing(2)} ${theme.spacing(3)}` }}>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Mollitia ea facilis ex molestias.
        Dolorem eos vitae excepturi ab reiciendis ut! Recusandae molestias eius voluptate facere?
        Iure incidunt architecto quo dicta?
      </Typography>
      <Button
        disableFocusRipple
        disableElevation
        variant="contained"
        size="medium"
        sx={{
          backgroundColor: 'skyBlue',
          color: 'navyBlue',
          marginLeft: 'auto',
          display: 'block',
          borderRadius: 1
        }}
        onClick={handleBulkImport}
        autoFocus>
        Bulk Import
      </Button>
    </Container>
  );
};

export default BulkDataHandler;
